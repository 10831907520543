import { Channel } from '../application/types/Channel';
import { Member, MemberRole } from '../application/types/Member';

export enum SearchKeywordType {
  CHANNEL_ID = 'CHANNEL_ID',
  EMAIL = 'EMAIL',
  ERROR = 'ERROR',
  USER_ID = 'USER_ID',
}

export const typeToSearch = (value?: string): SearchKeywordType => {
  if (!value) {
    return SearchKeywordType.ERROR;
  }

  switch (true) {
    case /^\d+$/.test(value):
      return SearchKeywordType.USER_ID;

    case /^\d+-\d+$/.test(value):
      return SearchKeywordType.CHANNEL_ID;

    case /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value):
      return SearchKeywordType.EMAIL;

    default:
      return SearchKeywordType.ERROR;
  }
};

export const getMember = (role: MemberRole, channel?: Channel | null): Member | undefined =>
  channel?.members.find((member) => member.role === role);

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { FCWithChildren } from '../../infrastructure/types/global';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { isDefined } from '../../utils/type-utils';
import { useParams, useNavigate } from 'react-router';
import { User } from '../../core/entities/User';
import { useTranslation } from 'react-i18next';
import { useToaster } from './ToasterContext';
import { userService } from '../services/UserService';
import ApiError from '../services/error/ErrorService';
import { UserDetailsTabs } from '../types/UserDetailsTabs';
import { ApplicationRoutes, RouteParts } from '../constants/navigation';
import { UserBanHistory } from '../types/UserBans';
import { UserDetailsMode } from '../../ui/components/Layout/UserDetails/UserDetailsMode';

interface UserDetailsContextProps {
  userDetails: User | null;
  userBanHistory: UserBanHistory[] | null;
  loading: boolean;
  error: string | null;
  unbanMutation: UseMutationResult<User, Error, User, unknown>;
  banMutation: UseMutationResult<User, Error, User, unknown>;
  confirmModalOpen: boolean;
  modalText: string;
  selectedTab: UserDetailsTabs;
  changeTab: (tab: UserDetailsTabs) => void;
  handleConfirmModal: () => void;
  closeConfirmModal: () => void;
  handleBanUnBanButtons: (e: React.MouseEvent<HTMLElement>, isBan: boolean, duration?: number) => void;
}

interface UserDetailsProviderProps {
  userVcIdProp?: string;
  modeProp: UserDetailsMode;
}

const UserDetailsContext = createContext<UserDetailsContextProps | undefined>(undefined);
const isUserDetailsTab = (tab: string | undefined): tab is UserDetailsTabs => {
  return Object.values(UserDetailsTabs).includes(tab as UserDetailsTabs);
};

export const UserDetailsProvider: FCWithChildren<UserDetailsProviderProps> = ({ children, userVcIdProp, modeProp }) => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userDetails, setUserDetails] = useState<User | null>(null);
  const [banDuration, setBanDuration] = useState<number | undefined>(undefined);
  const [userBanHistory, setUserBanHistory] = useState<UserBanHistory[] | null>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [modalText, setModalText] = useState(t('USER.BAN.CONFIRM'));
  const { userParam, tab: tabParam } = useParams<{ userParam: string; tab: string }>();
  const [userVcId] = useState<string | undefined>(isDefined(userVcIdProp) ? userVcIdProp : undefined);
  const [selectedTab, setSelectedTab] = useState<UserDetailsTabs>(
    isUserDetailsTab(tabParam) ? tabParam : UserDetailsTabs.PERSONAL_INFO,
  );
  const [mode] = useState(modeProp);
  const navigate = useNavigate();

  const changeTab = (tab: UserDetailsTabs): void => {
    setSelectedTab(tab);
    if (mode === UserDetailsMode.STANDLONE) {
      window.history.replaceState(null, '', `/${RouteParts.USERS}/${userVcId ?? userParam}/${tab}`);
    }
  };

  const fetchUserInfo = useCallback(async () => {
    try {
      let fetchedUserInfo: User;
      if (isDefined(userVcId)) {
        fetchedUserInfo = await userService.getUser(userVcId);
      } else if (isDefined(userParam)) {
        fetchedUserInfo = await userService.getUser(userParam);
      } else {
        throw new Error('No user id or user param provided');
      }

      setUserDetails(fetchedUserInfo);
    } catch (err) {
      if (err instanceof ApiError && err.status === 404) {
        navigate(ApplicationRoutes.NO_RESULTS);
      } else {
        const error = 'Failed to fetch user';
        console.error(error);
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  }, [userVcId, navigate, userParam]);

  const fetchUserBanHistory = useCallback(async () => {
    try {
      if (!isDefined(userDetails)) {
        throw new Error('userDetails is not defined');
      }
      const fetchedBanHistory = await userService.getUserBanHistory(userDetails);
      setUserBanHistory(fetchedBanHistory);
    } catch {
      const error = 'Failed to fetch user ban history';
      console.error(error);
      setError(error);
    }
  }, [userDetails]);

  const banMutation = useMutation({
    mutationFn: (banTarget: User) => userService.banUser(banTarget, banDuration),
    onSuccess: (a) => {
      setUserDetails(a);
      toaster.success(t('USER.BAN.TOASTER.SUCCESS'));
    },
    onError: (a) => {
      if (a instanceof ApiError) {
        if (toaster.defaultErrorsHandling(a.status, 'User')) {
          return;
        } else if (a.status === 409) {
          toaster.error(t('USER.BAN.TOASTER.ERROR.BANINPROGRESS'));
          return;
        }
        toaster.error(t('USER.BAN.TOASTER.ERROR.DEFAULT'));
      } else {
        toaster.error(t('USER.BAN.TOASTER.ERROR.DEFAULT'));
      }
    },
  });

  const unbanMutation = useMutation({
    mutationFn: (banTarget: User) => userService.unbanUser(banTarget),
    onSuccess: (a) => {
      setUserDetails(a);
      toaster.success(t('USER.UNBAN.TOASTER.SUCCESS'));
    },
    onError: (a) => {
      if (a instanceof ApiError) {
        if (toaster.defaultErrorsHandling(a.status, 'User')) {
          return;
        } else if (a.status === 409) {
          toaster.error(t('USER.UNBAN.TOASTER.ERROR.BANINPROGRESS'));
          return;
        }
        toaster.error(t('USER.UNBAN.TOASTER.ERROR.DEFAULT'));
      } else {
        toaster.error(t('USER.UNBAN.TOASTER.ERROR.DEFAULT'));
      }
    },
  });

  const handleConfirmModal = (): void => {
    setConfirmModalOpen(false);
    if (!isDefined(userDetails)) {
      return;
    }

    if (!userDetails?.isBanned) {
      banMutation.mutate(userDetails);
    } else {
      unbanMutation.mutate(userDetails);
    }
  };

  const closeConfirmModal = (): void => {
    setConfirmModalOpen(false);
  };

  const handleBanUnBanButtons = (e: React.MouseEvent<HTMLElement>, isBan: boolean, duration?: number): void => {
    e.preventDefault();
    const modalText = isBan ? t('USER.BAN.CONFIRM') : t('USER.UNBAN.CONFIRM');
    setBanDuration(duration);
    setModalText(modalText);
    setConfirmModalOpen(true);
  };

  useEffect(() => {
    if (isDefined(userVcId) || isDefined(userParam)) {
      fetchUserInfo();
    }
  }, [userVcId, navigate, fetchUserInfo, userParam]);

  useEffect(() => {
    if (userDetails && selectedTab === UserDetailsTabs.BANNING_HISTORY) {
      fetchUserBanHistory();
    }
  }, [userDetails, fetchUserBanHistory, selectedTab]);

  return (
    <UserDetailsContext.Provider
      value={{
        userDetails,
        userBanHistory,
        loading,
        error,
        unbanMutation,
        banMutation,
        confirmModalOpen,
        modalText,
        selectedTab,
        changeTab,
        handleConfirmModal,
        closeConfirmModal,
        handleBanUnBanButtons,
      }}
    >
      {children}
    </UserDetailsContext.Provider>
  );
};

export const useUserDetails = (): UserDetailsContextProps => {
  const context = useContext(UserDetailsContext);
  if (context === undefined) {
    throw new Error('useUserDetails must be used within a UserDetailsProvider');
  }
  return context;
};

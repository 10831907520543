import { Theme } from '@mui/material';
import { ModerationOutcome } from '../../../../../application/types/MessageModeration';
import { Message } from '../../../../../application/types/Message';
import { isDefined } from '../../../../../utils/type-utils';

const DEFAULT_BORDER_RADIUS = '4px';

export const getStyles = (
  theme: Theme,
  isSeller: boolean,
  message: Message,
): {
  backgroundColor: string;
  borderColor: string;
  borderRadius: string;
  showWarning: boolean;
} => {
  const { moderation, isSelected } = message;

  const baseStyles = {
    backgroundColor: isSeller ? theme.palette.notificationColor.light : theme.palette.notificationColor.main,
    borderColor: '#e8e8e8',
    borderRadius: DEFAULT_BORDER_RADIUS,
    showWarning: false,
  };

  const stylesMap = {
    highlighted: {
      backgroundColor: theme.palette.warning.light,
      borderColor: theme.palette.warning.main,
      borderRadius: isSeller ? '12px 12px 1px 12px' : '12px 12px 12px 1px',
      showWarning: true,
    },
    scamOrCircum: {
      backgroundColor: theme.palette.error.light,
      borderColor: theme.palette.error.main,
      showWarning: false,
    },
    safe: {
      backgroundColor: theme.palette.success.light,
      borderColor: theme.palette.success.main,
      showWarning: false,
    },
    flaggedAsScamOrCircum: {
      backgroundColor: theme.palette.error.light,
      borderColor: theme.palette.error.main,
      showWarning: false,
    },
    flaggedAsSafe: {
      backgroundColor: theme.palette.success.light,
      borderColor: theme.palette.success.main,
      showWarning: false,
    },
    selected: {
      backgroundColor: theme.palette.selectedColor.light,
      borderColor: theme.palette.selectedColor.main,
      borderRadius: DEFAULT_BORDER_RADIUS,
      showWarning: false,
    },
  };

  let appliedStyles = { ...baseStyles };

  if (moderation.required) {
    appliedStyles = { ...appliedStyles, ...stylesMap.highlighted };
  }

  if (
    moderation.moderationType === ModerationOutcome.SCAM ||
    moderation.moderationType === ModerationOutcome.CIRCUM ||
    moderation.moderationType === ModerationOutcome.OTHER
  ) {
    appliedStyles = { ...appliedStyles, ...stylesMap.scamOrCircum };
  }

  if (moderation.moderationType === ModerationOutcome.SAFE) {
    appliedStyles = { ...appliedStyles, ...stylesMap.safe };
  }
  if (isDefined(moderation.flaggedOutcome)) {
    if (
      moderation.flaggedOutcome === ModerationOutcome.SCAM ||
      moderation.flaggedOutcome === ModerationOutcome.CIRCUM ||
      moderation.flaggedOutcome === ModerationOutcome.OTHER
    ) {
      appliedStyles = { ...appliedStyles, ...stylesMap.flaggedAsScamOrCircum };
    } else if (moderation.flaggedOutcome === ModerationOutcome.SAFE) {
      appliedStyles = { ...appliedStyles, ...stylesMap.flaggedAsSafe };
    }
  }
  if (isSelected) {
    appliedStyles = { ...appliedStyles, ...stylesMap.selected };
  }

  return appliedStyles;
};

export interface UserBanHistory {
  actionType: BanType;
  moderator?: string;
  createdAt: Date;
  expiresAt?: Date;
  duration?: number;
  reason?: string;
  source: ActionSource;
  providerChannelId?: string;
}

export enum BanType {
  TEMPORARY_BAN = 'TEMPORARY_BAN',
  PERMANENT_BAN = 'PERMANENT_BAN',
  UNBAN = 'UNBAN',
}

export enum ActionSource {
  CHATMOD_MANUAL = 'CHATMOD_MANUAL',
  CHATMOD_AUTOMATIC = 'CHATMOD_AUTOMATIC',
  DATADOG = 'DATADOG',
  CHAMO = 'CHAMO',
  CHANNEL = 'CHANNEL',
  CUCHA = 'CUCHA',
  FRAUD_MANUAL = 'FRAUD_MANUAL',
}

export interface UserBanCount {
  temporaryBans: number;
  permanentBans: number;
  unbans: number;
}

import UserDetails from '../components/Layout/UserDetails/UserDetails';
import { Box } from '@mui/material';
import { UserDetailsMode } from '../components/Layout/UserDetails/UserDetailsMode';

const UserProfilePage = (): JSX.Element => {
  return (
    <Box className='UserProfilePage'>
      <UserDetails modeProp={UserDetailsMode.STANDLONE} />
    </Box>
  );
};

export default UserProfilePage;

export interface MessageModeration {
  required: boolean;
  moderationType?: ModerationOutcome;
  flaggedOutcome?: ModerationOutcome;
}

export enum ModerationOutcome {
  SCAM = 'SCAM',
  CIRCUM = 'CIRCUMVENTION',
  SAFE = 'SAFE',
  OTHER = 'OTHER',
  SAFE_AUTOMATIC = 'SAFE_AUTOMATIC',
}

import ChannelRepository from '../../infrastructure/repositories/ChannelRepository';
import { Channel } from '../types/Channel';

class ChannelService {
  public async getChannel(id?: string): Promise<Channel> {
    const {
      channelId,
      providerId,
      createdById,
      createdAt,
      productId,
      productPath,
      productPicture,
      members,
      messages,
      status,
      readOnly,
    } = await ChannelRepository.getChannelById(id);

    return {
      channelId,
      status,
      providerId,
      createdById,
      createdAt,
      productId,
      productPath,
      productPicture,
      members,
      messages: messages.map((message) => ({ ...message, isSelected: false })),
      readOnly,
    };
  }
}

export const channelService = new ChannelService();

import { Box, Button, CircularProgress, Divider, SxProps, Theme, ThemeProvider } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../theme';
import { useChannel } from '../../../../application/context/ChannelContext';
import { ModerationOutcome } from '../../../../application/types/MessageModeration';

interface ButtonMap {
  type: ModerationOutcome;
  color: 'error' | 'success';
  label: string;
}

const boxtyles: SxProps<Theme> = {
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'flex-end',
  p: `${theme.spacing(2)} ${theme.spacing(4)}`,
};

const ConversationsButtons: FC = () => {
  const { t } = useTranslation();
  const context = useChannel();

  const selectedButtonsMap: ButtonMap[] = [
    { type: ModerationOutcome.OTHER, color: 'error', label: 'CONVERSATIONS.CTA.OTHER' },
    { type: ModerationOutcome.SCAM, color: 'error', label: 'CONVERSATIONS.CTA.SCAM' },
    { type: ModerationOutcome.CIRCUM, color: 'error', label: 'CONVERSATIONS.CTA.CIRCUMVENTION' },
    { type: ModerationOutcome.SAFE, color: 'success', label: 'CONVERSATIONS.CTA.SAFE' },
  ];

  const unselectedButtons = (
    <>
      <Box sx={boxtyles}>
        <Button variant='outlined' color='info'>
          {t('CONVERSATIONS.CTA.SKIP')}
        </Button>
        <Button
          variant='black'
          disabled={!context.hasFlaggedAllRequiredMessages}
          onClick={() => context.submitMessageModeration()}
        >
          {t('CONVERSATIONS.CTA.DONE')}

          {context.loading && <CircularProgress style={{ marginLeft: theme.spacing(2) }} color='inherit' size={12} />}
        </Button>
      </Box>
    </>
  );

  const selectedButtons = (
    <ThemeProvider theme={theme}>
      <Box sx={boxtyles}>
        {selectedButtonsMap.map((buttonMap, index) => (
          <Button
            key={index}
            variant={'outlined'}
            color={buttonMap.color}
            onClick={() => context.flagSelectedMessagesForModeration(buttonMap.type)}
          >
            {t(buttonMap.label)}
          </Button>
        ))}
      </Box>
      <Divider />
    </ThemeProvider>
  );

  const activateRemoderateMode = (
    <Box sx={boxtyles}>
      <Button
        onClick={() => {
          context.editRemoderation();
        }}
        variant='black'
      >
        {t('CONVERSATIONS.CTA.RECLASSIFY')}
      </Button>
    </Box>
  );

  const buttonToDisplay = (): JSX.Element => {
    if (context.inRemoderateMode && context.inReadonlyMode) {
      return activateRemoderateMode;
    }
    return context.selectedMessages.length ? selectedButtons : unselectedButtons;
  };

  return buttonToDisplay();
};

export default ConversationsButtons;

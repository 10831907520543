import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { theme } from '../../../../theme';
import UserCard from '../UserCard/UserCard';
import { Member } from '../../../../application/types/Member';
import { isDefined } from '../../../../utils/type-utils';
import { t } from 'i18next';
import { UserBanCount } from '../../../../application/types/UserBans';
import UserDetails from '../UserDetails/UserDetails';
import { UserDetailsMode } from '../UserDetails/UserDetailsMode';
import CloseIcon from '@mui/icons-material/Close';

type ConversationsMemberProps = {
  title: string;
  color: string;
  member: Member;
  banCount?: UserBanCount;
  onUserDetailsModalOpenClose: (isOpen: boolean) => void;
};

const ConversationsMember: FC<ConversationsMemberProps> = ({
  title,
  color,
  member,
  banCount,
  onUserDetailsModalOpenClose,
}) => {
  const banCountText = (): string => {
    if (!isDefined(banCount) || (banCount.permanentBans === 0 && banCount.temporaryBans === 0)) {
      return '';
    }
    if (banCount.permanentBans === 0 && banCount.temporaryBans === 1) {
      return t('CONVERSATIONS.SIDEBAR.BAN.TEMPORARY');
    }
    if (banCount.permanentBans === 1 && banCount.temporaryBans === 0) {
      return t('CONVERSATIONS.SIDEBAR.BAN.PERMANENT');
    }
    return t('CONVERSATIONS.SIDEBAR.BAN.MULTI', { number: banCount.permanentBans + banCount.temporaryBans });
  };

  const [isUserDetailsDialogOpen, setIsUserDetailsDialogOpen] = useState(false);

  const handleClickOpen = (): void => {
    setIsUserDetailsDialogOpen(true);
    onUserDetailsModalOpenClose(true);
  };

  const handleClose = (): void => {
    setIsUserDetailsDialogOpen(false);
    onUserDetailsModalOpenClose(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: color,
        borderRadius: '4px',
        border: `1px solid ${theme.palette.grey[300]}`,
        marginBottom: theme.spacing(1),
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      }}
    >
      <Typography>
        {title}:{' '}
        <button onClick={handleClickOpen}>
          <u>
            <b>{member.vcId}</b>
          </u>
        </button>
        <Dialog
          sx={{
            '& .MuiDialog-paper': {
              width: '75%',
              height: '762px',
              maxWidth: 'none',
            },
          }}
          open={isUserDetailsDialogOpen}
          onClose={handleClose}
        >
          <DialogTitle>
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              })}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <UserDetails userVcIdProp={member.vcId.toString()} modeProp={UserDetailsMode.EMBEDDED}></UserDetails>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </Typography>
      <UserCard
        src={member.profilePictureUrl}
        sx={{ backgroundColor: 'transparent', margin: `${theme.spacing(1)} 0` }}
        topLineText={member.username}
        bottomLineText={member.username}
      />
      <Typography variant='body2' color={theme.palette.error.main}>
        {banCountText()}
      </Typography>
    </Box>
  );
};

export default ConversationsMember;

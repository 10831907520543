import { UserBanBody, UserBanHistoryDto } from '../../application/types/dto/UserBansDto';
import { UserDto } from '../../application/types/dto/UserDto';
import { Member } from '../../application/types/Member';
import { User } from '../../core/entities/User';
import PapiClient from '../api/PapiClient';

export const getUserByEmail = async (value: string): Promise<UserDto> => {
  return PapiClient.get<UserDto>(`/users/email/${value}`);
};

export const getUserByVcId = async (value: string): Promise<UserDto> => {
  return PapiClient.get<UserDto>(`/users/vcId/${value}`);
};

export const patchBanUser = async (userId: number, duration?: number): Promise<UserDto> => {
  const body: UserBanBody = {
    duration,
  };
  return PapiClient.patch<UserDto, UserBanBody>(`/users/${userId}/bans`, body);
};

export const patchUnbanUser = async (userId: number): Promise<UserDto> => {
  return PapiClient.patch<UserDto, undefined>(`/users/${userId}/unbans`);
};

export const getUserBanHistory = async (
  user: User | Member,
  sortingOption: BanHistorySortingOptions = BanHistorySortingOptions.ID_DESC,
): Promise<UserBanHistoryDto> => {
  return PapiClient.get<UserBanHistoryDto>(`/history/users/${user.id}/bans?sortBy=${sortingOption}`);
};

enum BanHistorySortingOptions {
  ID_ASC = 'asc(id)',
  ID_DESC = 'desc(id)',
}

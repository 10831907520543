import { Box, Button, Divider, Tabs, Tab, Typography, MenuItem, Menu } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserDetailsProvider, useUserDetails } from '../../../../application/context/UserDetailsContext';
import { theme } from '../../../../theme';
import { isDefined } from '../../../../utils/type-utils';
import ConfirmModal from '../../FloatingStuff/ConfirmModal';
import UserCard from '../UserCard/UserCard';
import UserDetailsTable from './UserDetailsTab';
import { UserDetailsTabs } from '../../../../application/types/UserDetailsTabs';
import UserBanHistoryTab from './UserBanHistoryTab';
import { UserDetailsMode } from './UserDetailsMode';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import React from 'react';

interface UserDetailsProps {
  userVcIdProp?: string;
  modeProp: UserDetailsMode;
}

const UserDetails = ({ userVcIdProp, modeProp }: UserDetailsProps): JSX.Element => {
  const { t } = useTranslation();

  const UserDetailsDisplay = (): JSX.Element => {
    const {
      userDetails,
      userBanHistory,
      loading,
      error,
      unbanMutation,
      banMutation,
      confirmModalOpen,
      modalText,
      selectedTab,
      changeTab,
      handleBanUnBanButtons,
      handleConfirmModal,
      closeConfirmModal,
    } = useUserDetails();

    const handleTabChange = (event: React.ChangeEvent<{}>, newTab: UserDetailsTabs): void => {
      changeTab(newTab);
    };

    return (
      <>
        {loading && <Typography>is loading</Typography>}
        {!isDefined(error) && isDefined(userDetails) && (
          <>
            <Box
              sx={{
                p: theme.spacing(3),
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <UserCard
                src={userDetails.profilePictureUrl}
                topLineText={`${t('USER.AVATAR.PRETEXT')}: ${userDetails.vcId}`}
                bottomLineText={userDetails.email}
              />
              {userDetails.isBanned ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {isDefined(userDetails.banExpiryDate) && (
                    <Typography
                      sx={{
                        alignContent: 'center',
                        marginRight: theme.spacing(1),
                      }}
                    >
                      {t('USER.BANUNTIL', {
                        banEndDate: userDetails.banExpiryDate.toLocaleDateString(),
                      })}
                    </Typography>
                  )}
                  <Button
                    onClick={(e) => handleBanUnBanButtons(e, false)}
                    variant='outlined'
                    disabled={unbanMutation.isPending}
                  >
                    {t('USER.CTA.UNBAN')}
                  </Button>
                </div>
              ) : (
                <PopupState variant='popover' popupId='demo-popup-menu'>
                  {(popupState) => (
                    <React.Fragment>
                      <Button
                        variant='outlined'
                        color='error'
                        disabled={banMutation.isPending}
                        {...bindTrigger(popupState)}
                      >
                        {t('USER.CTA.BAN')}
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem
                          onClick={(e) => {
                            popupState.close();
                            handleBanUnBanButtons(e, true, 24);
                          }}
                        >
                          {t('USER.CTA.BAN24')}
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            popupState.close();
                            handleBanUnBanButtons(e, true);
                          }}
                        >
                          {t('USER.CTA.BANPERMANENT')}
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              )}
            </Box>
            <Divider sx={{ marginBottom: theme.spacing(2) }} />
            <Tabs value={selectedTab} textColor='secondary' indicatorColor='secondary' onChange={handleTabChange}>
              <Tab
                label={t('USER.DETAILS.TABLABELS.PERSONALINFO')}
                sx={{
                  fontWeight: selectedTab === UserDetailsTabs.PERSONAL_INFO ? 'bold' : 'normal',
                  textTransform: 'none',
                  fontSize: '1rem',
                  cursor: 'pointer',
                }}
                value={UserDetailsTabs.PERSONAL_INFO}
              />
              <Tab
                label={t('USER.DETAILS.TABLABELS.CONVERSATION')}
                sx={{
                  fontWeight: selectedTab === UserDetailsTabs.CONVERSATION ? 'bold' : 'normal',
                  textTransform: 'none',
                  fontSize: '1rem',
                  cursor: 'pointer',
                }}
                value={UserDetailsTabs.CONVERSATION}
              />
              <Tab
                label={t('USER.DETAILS.TABLABELS.BANHISTORY')}
                sx={{
                  fontWeight: selectedTab === UserDetailsTabs.BANNING_HISTORY ? 'bold' : 'normal',
                  textTransform: 'none',
                  fontSize: '1rem',
                  cursor: 'pointer',
                }}
                value={UserDetailsTabs.BANNING_HISTORY}
              />
            </Tabs>
            <Box sx={{ p: theme.spacing(3) }}>
              {selectedTab === UserDetailsTabs.PERSONAL_INFO && (
                <UserDetailsTable userInfos={userDetails}></UserDetailsTable>
              )}

              {selectedTab === UserDetailsTabs.CONVERSATION && <Typography>Conversation</Typography>}
              {selectedTab === UserDetailsTabs.BANNING_HISTORY && userBanHistory && (
                <UserBanHistoryTab banHistory={userBanHistory}></UserBanHistoryTab>
              )}
            </Box>
            <ConfirmModal
              open={confirmModalOpen}
              text={modalText}
              onCancel={() => {
                closeConfirmModal();
              }}
              onConfirm={() => {
                handleConfirmModal();
              }}
            />
          </>
        )}
      </>
    );
  };

  return (
    <UserDetailsProvider userVcIdProp={userVcIdProp} modeProp={modeProp}>
      <UserDetailsDisplay />
    </UserDetailsProvider>
  );
};

export default UserDetails;

import { User } from '../../core/entities/User';
import {
  getUserBanHistory,
  getUserByEmail,
  getUserByVcId,
  patchBanUser,
  patchUnbanUser,
} from '../../infrastructure/repositories/UserRepository';
import { stringToDate } from '../../utils/date-utils';
import { SearchKeywordType, typeToSearch } from '../../utils/search-utils';
import { isDefined } from '../../utils/type-utils';
import { UserDto } from '../types/dto/UserDto';
import { Member } from '../types/Member';
import { ActionSource, BanType, UserBanCount, UserBanHistory } from '../types/UserBans';

class UserService {
  private banHistoryCache: Map<string, UserBanHistory[]> = new Map();
  private banHistoryOnGoingRequests: Map<string, Promise<UserBanHistory[]>> = new Map();

  private invalidateBanHistoryCacheForUser(user: User | Member): void {
    this.banHistoryCache.delete(user.id.toString());
  }

  public async getUser(user: string): Promise<User>;
  public async getUser(user: Member): Promise<User>;

  public async getUser(user: string | Member): Promise<User> {
    if (!isDefined(user)) {
      throw new Error('Invalid value');
    }

    let response: Promise<UserDto>;

    try {
      if (typeof user === 'string') {
        const searchEmail = typeToSearch(user) === SearchKeywordType.EMAIL;
        response = searchEmail ? getUserByEmail(user) : getUserByVcId(user);
      } else {
        response = getUserByVcId(user.vcId);
      }
    } catch {
      throw new Error('Invalid value');
    }

    return this.mapUserResponse(response);
  }

  public async banUser(userToBan?: User, duration?: number): Promise<User> {
    if (!isDefined(userToBan)) {
      throw new Error('Invalid value');
    }

    this.invalidateBanHistoryCacheForUser(userToBan);

    const response = patchBanUser(userToBan.id, duration);

    return this.mapUserResponse(response);
  }

  public async unbanUser(userToUnban?: User): Promise<User> {
    if (!isDefined(userToUnban)) {
      throw new Error('Invalid value');
    }

    this.invalidateBanHistoryCacheForUser(userToUnban);

    const response = patchUnbanUser(userToUnban.id);

    return this.mapUserResponse(response);
  }

  public async mapUserResponse(response: Promise<UserDto>): Promise<User> {
    const responseData = await response;
    const banExpiryDate = isDefined(responseData.banExpiresAt) ? stringToDate(responseData.banExpiresAt) : undefined;

    const mappedResponse: User = await {
      ...responseData,
      banExpiryDate,
    };

    return mappedResponse;
  }

  public async getUserBanCount(user: User | Member): Promise<UserBanCount> {
    const history = await this.getUserBanHistory(user);

    const temporaryBans = history.filter((ban) => ban.actionType === BanType.TEMPORARY_BAN).length;
    const permanentBans = history.filter((ban) => ban.actionType === BanType.PERMANENT_BAN).length;
    const unbans = history.filter((ban) => ban.actionType === BanType.UNBAN).length;

    return {
      temporaryBans,
      permanentBans,
      unbans,
    };
  }

  public async getUserBanHistory(user: User | Member): Promise<UserBanHistory[]> {
    const userId = user.id.toString();

    if (this.banHistoryCache.has(userId)) {
      return this.banHistoryCache.get(userId)!;
    }

    if (this.banHistoryOnGoingRequests.has(userId)) {
      return this.banHistoryOnGoingRequests.get(userId)!;
    }

    const banHistoryRequest: Promise<UserBanHistory[]> = (async (): Promise<UserBanHistory[]> => {
      try {
        const { history } = await getUserBanHistory(user);

        const mappedBanHistory = this.banHistoryDtoMap(history);

        this.banHistoryCache.set(userId, mappedBanHistory);

        return mappedBanHistory;
      } finally {
        this.banHistoryOnGoingRequests.delete(userId);
      }
    })();

    this.banHistoryOnGoingRequests.set(userId, banHistoryRequest);

    return banHistoryRequest;
  }

  private banHistoryDtoMap(
    history: {
      actionType: BanType;
      moderator?: string;
      createdAt: string;
      expiresAt?: string;
      duration?: number;
      reason?: string;
      source: ActionSource;
      providerChannelId?: string;
    }[],
  ): UserBanHistory[] {
    return history.map((ban) => {
      const expiresAt = isDefined(ban.expiresAt) ? stringToDate(ban.expiresAt) : undefined;
      const createdAt = stringToDate(ban.createdAt);

      return {
        ...ban,
        createdAt,
        expiresAt,
      };
    });
  }
}

export const userService = new UserService();

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { FCWithChildren } from '../../infrastructure/types/global';
import { Channel, ChannelStatus } from '../types/Channel';
import { channelService } from '../services/ChannelService';
import { isDefined, isEmptyString } from '../../utils/type-utils';
import { socket, SOCKET_MESSAGE_DETAILS_EVENT } from '../../infrastructure/websocket/socket';
import { MessageFromSocket } from '../types/Message';
import ApiError from '../services/error/ErrorService';
import { ApplicationRoutes } from '../constants/navigation';
import { ModerationOutcome } from '../types/MessageModeration';
import { messageService } from '../services/MessageService';
import { getMember } from '../../utils/search-utils';
import { userService } from '../services/UserService';
import { UserBanCount } from '../types/UserBans';
import { MemberRole } from '../types/Member';

interface ChannelContextProps {
  channel: Channel | null;
  providerId: string | undefined;
  inReadonlyMode: boolean;
  inRemoderateMode: boolean;
  loading: boolean;
  sellerBanCount: UserBanCount | undefined;
  buyerBanCount: UserBanCount | undefined;
  lastNewMessageIdSeen: number | null;
  error: string | null;
  onUserDetailsModalOpenClose: (isOpen: boolean) => void;
  editRemoderation: () => void;
  resetLastMessageSeenId: () => void;
  toggleMessageCheck: (messageId: number) => void;
  flagSelectedMessagesForModeration: (ModerationOutcome: ModerationOutcome) => void;
  submitMessageModeration: () => Promise<void>;
  hasFlaggedAllRequiredMessages: boolean;
  selectedMessages: number[];
}

const ChannelContext = createContext<ChannelContextProps | undefined>(undefined);

export const ChannelProvider: FCWithChildren = ({ children }) => {
  const params = useParams<{ '*': string }>();
  const [providerId, setProviderId] = useState<string | undefined>(
    isEmptyString(params['*']) ? undefined : params['*'],
  );
  const [channel, setChannel] = useState<Channel | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [inReadonlyMode, setInReadonlyMode] = useState(true);
  const [inRemoderateMode, setInRemoderateMode] = useState(false);
  const [canEditInRemoderate, setCanEditInRemodereate] = useState(false);
  const [lastNewMessageIdSeen, setLastNewMessageIdSeen] = useState<null | number>(null);
  const [sellerBanCount, setSellerBanCount] = useState<UserBanCount>();
  const [buyerBanCount, setBuyerBanCount] = useState<UserBanCount>();
  const [error, setError] = useState<string | null>(null);
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isDefined(channel)) {
      return;
    }
    if (channel.status === ChannelStatus.IN_PROCESSING) {
      setInReadonlyMode(channel.readOnly);
    } else {
      setInReadonlyMode(channel.status !== ChannelStatus.UNPROCESSED && !canEditInRemoderate);
    }
    setInRemoderateMode(channel.status === ChannelStatus.PROCESSED);
  }, [channel, canEditInRemoderate]);

  useEffect(() => {
    socket.connect();

    return (): void => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    function onNewMessage(value: MessageFromSocket): void {
      if (!isDefined(channel) || channel.channelId != value.channelId.toString()) {
        return;
      }

      if (!isDefined(lastNewMessageIdSeen)) {
        setLastNewMessageIdSeen(value.id);
      }
      channel.messages.push(value);
      setChannel({ ...channel });
    }

    socket.on(SOCKET_MESSAGE_DETAILS_EVENT, onNewMessage);

    return (): void => {
      socket.off(SOCKET_MESSAGE_DETAILS_EVENT, onNewMessage);
    };
  }, [channel, lastNewMessageIdSeen]);

  useEffect(() => {
    if (!isDefined(channel)) {
      return;
    }
    updateMemberBansCount(channel);
  }, [isUserDetailsModalOpen, channel]);

  const resetLastMessageSeenId = (): void => {
    setLastNewMessageIdSeen(null);
  };

  const onUserDetailsModalOpenClose = (isOpen: boolean): void => {
    setIsUserDetailsModalOpen(isOpen);
  };

  const fetchChannel = useCallback(async () => {
    try {
      const fetchedChannel = await channelService.getChannel(providerId);
      setCanEditInRemodereate(false);
      setChannel(fetchedChannel);
      updateMemberBansCount(fetchedChannel);
    } catch (err) {
      if (err instanceof ApiError && err.status === 404) {
        navigate(ApplicationRoutes.NO_RESULTS);
      } else {
        setError('Failed to fetch channel');
      }
    } finally {
      setLoading(false);
    }
  }, [providerId, navigate]);

  useEffect(() => {
    setProviderId(isEmptyString(params['*']) ? undefined : params['*']);

    fetchChannel();
  }, [providerId, navigate, params, fetchChannel]);

  const toggleMessageCheck = (messageId: number): void => {
    if (!isDefined(channel)) {
      return;
    }

    const updatedChannel = { ...channel };

    updatedChannel.messages = updatedChannel.messages.map((message) => {
      if (message.id === messageId) {
        return { ...message, isSelected: !message.isSelected };
      }

      return message;
    });

    setChannel(updatedChannel);
  };

  const updateMemberBansCount = async (fetchedChannel: Channel): Promise<void> => {
    const buyer = getMember(MemberRole.BUYER, fetchedChannel);
    const seller = getMember(MemberRole.SELLER, fetchedChannel);

    if (!isDefined(buyer) || !isDefined(seller)) {
      return;
    }

    const buyerBanCount = await userService.getUserBanCount(buyer);
    const sellerBanCount = await userService.getUserBanCount(seller);
    setBuyerBanCount(buyerBanCount);
    setSellerBanCount(sellerBanCount);
  };

  const getSelectedMessages = (): number[] | undefined =>
    channel?.messages.filter((message) => message.isSelected).map((message) => message.id);

  const hasFlaggedAllRequiredMessages = (): boolean => {
    if (!isDefined(channel)) {
      return false;
    }

    if (channel.status === ChannelStatus.PROCESSED) {
      return channel.messages.filter((message) => isDefined(message.moderation.flaggedOutcome)).length !== 0;
    }

    return channel.messages
      .filter((message) => message.moderation.required)
      .every((message) => isDefined(message.moderation.flaggedOutcome));
  };

  const flagSelectedMessagesForModeration = (ModerationOutcome: ModerationOutcome): void => {
    const selectedMessages = getSelectedMessages();

    if (!isDefined(channel) || !isDefined(selectedMessages)) {
      return;
    }

    const updatedChannel = { ...channel };

    updatedChannel.messages = updatedChannel.messages.map((message) => {
      if (selectedMessages.includes(message.id)) {
        return {
          ...message,
          isSelected: false,
          moderation: { ...message.moderation, flaggedOutcome: ModerationOutcome },
        };
      }

      return message;
    });

    setChannel(updatedChannel);
  };

  const editRemoderation = (): void => {
    if (inRemoderateMode) {
      setCanEditInRemodereate(true);
    }
  };

  const submitMessageModeration = async (): Promise<void> => {
    if (!isDefined(channel)) {
      return;
    }

    setLoading(true);

    try {
      return messageService.classifyMessages(channel.channelId, channel.messages).then(() => {
        fetchChannel().then(() => {
          setLoading(false);
        });
      });
    } catch (error: unknown) {
      console.error(error);
      const errorMessage = 'Failed to submit message moderation';
      setError(errorMessage);
      throw new Error(errorMessage);
    }
  };

  return (
    <ChannelContext.Provider
      value={{
        channel,
        providerId,
        inReadonlyMode,
        inRemoderateMode,
        lastNewMessageIdSeen,
        loading,
        onUserDetailsModalOpenClose,
        error,
        toggleMessageCheck,
        flagSelectedMessagesForModeration,
        submitMessageModeration,
        resetLastMessageSeenId,
        editRemoderation,
        buyerBanCount,
        sellerBanCount,
        hasFlaggedAllRequiredMessages: hasFlaggedAllRequiredMessages(),
        selectedMessages: getSelectedMessages() ?? [],
      }}
    >
      {children}
    </ChannelContext.Provider>
  );
};

export const useChannel = (): ChannelContextProps => {
  const context = useContext(ChannelContext);
  if (!isDefined(context)) {
    throw new Error('useChannel must be used within a ChannelProvider');
  }
  return context;
};

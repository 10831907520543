import { Member } from './Member';
import { Message } from './Message';
export interface Channel {
  channelId: string;
  status: ChannelStatus;
  providerId: string;
  createdById: number;
  createdAt: string;
  productId: number;
  productPath: string;
  productPicture: string;
  members: Member[];
  messages: Message[];
  readOnly: boolean;
}

export enum ChannelStatus {
  UNPROCESSED = 'UNPROCESSED',
  IN_PROCESSING = 'IN_PROCESSING',
  PROCESSED = 'PROCESSED',
}
